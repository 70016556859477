<template>
  <div>
    <b-modal
      ref="stockslip-history"
      hide-footer
      title="Lịch sử chỉnh sửa"
      size="xl"
    >
      <b-row>
        <b-col md="3">
          <label class="lable-sp">Chỉnh sửa bởi: </label>
        </b-col>
        <b-col md="8">
          <label>{{ history.createdBy }}</label>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col md="3">
          <label class="lable-sp">Chỉnh sửa lúc: </label>
        </b-col>
        <b-col md="9">
          <label>{{ history.createdAt }}</label>
        </b-col>
      </b-row>
      <h5 class="bold-text">Chi tiết thay đổi thông tin</h5>
      <hr />
      <b-table
        bordered
        hover
        :fields="fields"
        :items="history.diffValues"
        class="col-md-8"
      >
        <template v-slot:cell(originalValue)="row">
          <div style="text-align: end" v-if="row.item.isNumber">
            <span v-text="convertPrice(row.item.originalValue)"></span>
          </div>
          <span v-else v-text="row.item.originalValue"></span>
        </template>
        <template v-slot:cell(newValue)="row">
          <div style="text-align: end" v-if="row.item.isNumber">
            <span v-text="convertPrice(row.item.newValue)"></span>
          </div>
          <span v-else v-text="row.item.newValue"></span>
        </template>
      </b-table>
      <h5 class="bold-text">Chi tiết thay đổi sản phẩm</h5>
      <hr />
      <table
        class="table table-bordered table-vertical-center table-hover mb-10"
      >
        <tr>
          <th style="width: 50%">Trước</th>
          <th>Sau</th>
        </tr>
        <tr v-for="(item, index) in history.historyItems" :key="index">
          <td>
            <b-row v-show="item.originalItem.productName">
              <b-col class="col col-sm-3">
                <p class="header-color">Sản phẩm:</p>
                <p class="header-color">IMEI:</p>
                <p class="header-color">Số lượng:</p>
                <p class="header-color">Đơn giá:</p>
                <p class="header-color">Chiết khấu:</p>
                <p class="header-color">Thành tiền:</p>
              </b-col>
              <b-col>
                <p v-html="item.originalItem.productName || '<br/>'"></p>
                <p v-html="item.originalItem.productImei || '<br/>'"></p>
                <p>
                  {{ convertPrice(item.originalItem.quantity) }}
                </p>
                <p>{{ convertPrice(item.originalItem.unitPrice) }}</p>
                <p>{{ convertPrice(item.originalItem.discountAmount) }}</p>
                <p>{{ convertPrice(item.originalItem.totalAmount) }}</p>
              </b-col>
            </b-row>
          </td>
          <td>
            <b-row v-show="item.newItem.productName">
              <b-col class="col col-sm-3">
                <p class="header-color">Sản phẩm:</p>
                <p class="header-color">IMEI:</p>
                <p class="header-color">Số lượng:</p>
                <p class="header-color">Đơn giá:</p>
                <p class="header-color">Chiết khấu:</p>
                <p class="header-color">Thành tiền:</p>
              </b-col>
              <b-col>
                <p v-html="item.newItem.productName || '<br/>'"></p>
                <p v-html="item.newItem.productImei || '<br/>'"></p>
                <p>
                  {{ convertPrice(item.newItem.quantity) }}
                </p>
                <p>{{ convertPrice(item.newItem.unitPrice) }}</p>
                <p>{{ convertPrice(item.newItem.discountAmount) }}</p>
                <p>{{ convertPrice(item.newItem.totalAmount) }}</p>
              </b-col>
            </b-row>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { convertPrice } from '../../../utils/common';

export default {
  data() {
    return {
      history: {
        createdBy: '',
        createdAt: '',
        diffValues: [],
        historyItems: [],
      },
      fields: [
        {
          key: 'keyName',
          label: 'Thông tin',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'originalValue',
          label: 'Trước',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
        {
          key: 'newValue',
          label: 'Sau',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
      ],
      onLoading: false,
    };
  },
  methods: {
    showModal: function (id) {
      console.log('show modal');
      this.getDetailHistory(id);
    },
    getDetailHistory: function (id) {
      this.onLoading = true;
      ApiService.get(`/histories/stockslip/${id}`).then((res) => {
        this.history = res.data.data;
        this.$refs['stockslip-history'].show();
      });
      this.onLoading = false;
    },
    convertPrice(value) {
      return convertPrice(value);
    },
  },
};
</script>

<style scoped>
.lable-sp {
  font-weight: 500;
}
.header-color {
  font-weight: 450;
}
</style>
